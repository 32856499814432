.error-page {
  width: 100%;
  background: linear-gradient(to bottom, #ff9a67, #fc024d);
  overflow-y: hidden;
  font-family: "Avenir";
  max-width: 425px;
  margin: auto;
  & .inner-cont {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .error-img {
      width: 70%;
      max-width: 500px;
      margin-top: 20%;
    }

    & .error-heading {
      color: #fc024c;
      font-size: 70px;
      font-weight: 300;
    }

    & .error-subheading {
      text-align: center;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 900;
      margin: 30px;
      line-height: 1.6;
    }

    & .back-btn {
      border-radius: 33px;
      background-color: #fff;
      font-weight: 900;
      color: #000;
      border: none;
      padding: 16px 70px;
      margin-bottom: 80px;
      outline-style: none;
      text-align: center;
      text-decoration: none;
      font-size: 1.2rem;
    }
  }
}
/* body {
  margin: 0;
} */
